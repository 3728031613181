import React from 'react'

import HeaderSlide from '../components/HeaderSlide'
import Search from '../components/Search'
import Welcome from '../components/Welcome'
import HomeRooms from '../components/HomeRooms'
import Call from '../components/Call'
import Amenities from '../components/Amenities'
import BookingForm from '../components/BookingForm'
// import VideoPlayer from '../components/VideoPlayer'

const Layouts = () => {
  return (
    <div className='layouts'>
      <HeaderSlide />

      <Welcome />
      {/* <VideoPlayer /> */}
      <HomeRooms />
      <Search />
      <Amenities />
      <BookingForm />
      <Call />
    </div>
  )
}

export default Layouts
